.input-table {
  width: 100%;
  text-align: center;
  border: black;
}

.btn-circle-tinny {
  width: 30px;
  height: 30px;
  padding: 1px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.table-padding {
  padding-left: 60px;
}

.table td {
  padding: 0.1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.btn-download-excel {
  width: 19%;
  height: 68%;
  background-color: silver;
  border-radius: 50%;

  margin: 1em;
}

.btn-download-excel:hover {
  background-color: rgb(138, 137, 137);
}

/* For width 400px and larger: */
@media all and (min-width: 200px) and (max-width: 500px) {
  .btn-download-excel {
    width: 33%;
    height: 71%;
    background-color: silver;
    border-radius: 50%;
    padding: 1em;
    margin: 1em;
  }
}

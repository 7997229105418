.code-snippet {
  background: #afb3b8;
  padding: 0.1rem;
  border-radius: 9px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.code-section {
  position: relative;
  width: 100%;
}

.code-section pre {
  overflow-x: hidden;
  margin-bottom: 0px;
}

@media only screen and (min-width: 600px) {
  .code-section span {
    position: absolute;
    right: 94%;
    top: -29%;
    bottom: 0;
    font-size: 120%;
  }
}

@media only screen and (max-width: 600px) {
  .code-section span {
    /* position: absolute; */
    /* right: 94%; */
    top: -173%;
    bottom: 0;
    font-size: 120%;
  }
}

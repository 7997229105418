.btn-circle {
  width: 50px;
  height: 50px;
  padding: 6px 0px;
  border-radius: 27px !important;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  color: #ffffff !important;
  margin: 8px;
}
svg {
  pointer-events: none;
}
.icon-edit {
  cursor: pointer;
}

.konvajs-content {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.close-popup {
  color: black;
  cursor: pointer;
}
#container2 {
  margin: 8%;
}
#container {
  margin-top: 3%;
  margin-bottom: 3%;
}
.button-save-canvas {
  margin-top: 10px;
  margin-bottom: 10px;
}

#add-image-label-file > .addimage {
  height: 41px;
  width: 41px;
  cursor: pointer;
  text-align: center;
}
#add-image-button {
  display: none;
}

@media only screen and (min-width: 600px) {
  .popup_inner_canvas {
    overflow: auto;
    position: absolute;
    left: 17%;
    right: 17%;
    top: 10%;
    bottom: 10%;
    margin: auto;
    background: white;
  }

  .popup_inner_info {
    overflow: auto;
    position: absolute;
    left: 16%;
    right: 16%;
    top: 9%;
    bottom: 1%;
    margin: auto;
    background: white;
  }
  .mr-w {
    margin-right: 1rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .popup_inner_canvas {
    overflow: auto;
    position: absolute;
    left: 3%;
    right: 3%;
    top: 14%;
    bottom: 12%;
    margin: auto;
    background: white;
  }

  .popup_inner_info {
    overflow: auto;
    position: absolute;
    left: 3%;
    right: 3%;
    top: 11%;
    bottom: 1%;
    margin: auto;
    background: white;
  }
}

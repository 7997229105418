p.return-line {
  width: 300px;
  margin: auto;
  padding: 10px;
  font-size: 1.2em;
  word-wrap: break-word;
}
.data-url-title {
  margin: auto;
  padding: 10px;
  font-size: 1.2em;
  word-wrap: break-word;
}
.qrcode-row {
  overflow-x: auto;
  width: 250px;
}
@media (max-width: 550px) {
  .qrcode-row {
    overflow-x: auto;
    width: 100px;
  }
  p.return-line {
    width: 100px;
    margin: auto;
    padding: 10px;
    font-size: 1.2em;
    word-wrap: break-word;
  }
}

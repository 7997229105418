.pagination > li > a {
  padding: 1em;
}
.pagination > li.active {
  box-shadow: 0 0 2px #1b7d9e;
  background-color: #f3fcff;
  color: black;
}
.pagination > li {
  padding-top: 1em;
  padding-bottom: 1em;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  background-color: #343a40;
  color: white;
}

.qrcoderow {
  overflow-x: auto;
  width: 250px;
}

.inc_button {
  -webkit-writing-mode: horizontal-tb !important;
  margin: 0 0 0 5px;
  /* text-indent: -9999px; */
  cursor: pointer;
  width: 31px;
  height: 29px;
  float: right;
  text-align: center;
  border-radius: 50%;
  /* background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAA6CAMAAAB75KKzAAABgFBMVEX////+/v79/f38/Pz7+/v6+vr5+fn4+Pj39/f29vb19fX09PTz8/Py8vLx8fHw8PDv7+/u7u7t7e3s7Ozr6+vq6urp6eno6Ojn5+fm5ubl5eXj4+Pi4uLh4eHf39/e3t7d3d3b29vZ2dnX19fW1tbU1NTS0tLQ0NDOzs7Nzc3KysrIyMjHx8fGxsbExMTCwsLAwMC+vr69vb28vLy8ubq6urq5ubm4uLi5tra2tra1tbWzs7O0sbKysrKxsLCxrq6urq6vrKyuq6ysrKytqqqqqqqrqamoqKinp6eppqamo6SkpKSioqKhoaGgoKCfn5+fnZ2cnJyampqZmZmamJiZlpaWlpaTk5OSkpKUkZGQkJCOjo6PjIyMjIyOiYqKioqJiYmJhISEhISGgYKCgoKEgIB+fn5/e3t9eHl7d3d4eHhzc3N0bm9vb29sZmdnZ2diYGBiW1xfWFlaWlpdVVZVVVVXT1BSS0xKSkpFRUVHPj9AQEA9PT05OTk6MDEwJierkKnYAAACRklEQVR42t3TPWsUURTG8f/znDObNTEbYgwRRAQR1EJFImIlWgk2Wlj4ZfwcfgMR7MRGsFARhSBIFCw0xldI1ARfEpLVXaPFTDa7yvTiMMVwn7l3ztx7fnmZ6uouz6x8+zTZGj0xkZtjmw/tezMAWlpilhOnmv3pxqM7HQEIgEezp6fdSzvXX1fj1Qvd23MXiyrtXF1wf4bg3bVLBST8urGowQjBx5sXRMLjeW+F6t1vnx4hWbvrXjZ9Zv1Kmcoxs384uY9786bZdmBOZWg9OZmd59FbV0CGhEJ2vDyeH/FmNWVtKWTbklfzRfYmCkGmZMuSeJ/LsbWqgAhLgSWxnO0Awdg0QBMO78P4viypnZ1y7vmi2qyJCYBTDyX8M4d/lKUycCkc8lCOdBHi5jEwkwWfvwt1ZxuWPJ67VwWw9kBI5woW5klrSIYYzz1zZckW5ZE2FDJhrFa28peEhGULYoiwFSCNZHHoOciErACysAJJYsrJ3lcbIUuWA1ADSwZpB0nz6DNZpsD6MtVdTiMRMFWQsGvlAwVW4MVFh6l2bwwStP/HOiHZqKwGQ3NS5R754JsVyZgQKk90ZNKbO5j7Pi+Us4ww1tiotrpdO1pflyQjYzG6PQel5MR4u93tdIYa0Wz4T0ewsdJT5v9HWdX0NcoqEHXKhKhXVrZYrTKhWmXlJ/9WptZOwMIONK9BZTo72seo8VIDynA/sgwNKKPbn3YbGlCmW6LqvFpl5Y/WKbMUrlVWgalRZoKoVRbIqlVmyqr/bWW/AWRxuFeEURi2AAAAAElFTkSuQmCC)
    no-repeat; */
}
.dec_button {
  margin: 0 0 0 5px;
  /* text-indent: -9999px; */
  cursor: pointer;
  width: 29px;
  height: 29px;
  float: right;
  text-align: center;
  border-radius: 50%;
  /* background: url(./../../img/incbutton.png) no-repeat; */
  background-position: 0 -29px;
}

.MuiSvgIcon-root {
  width: 9em;
  height: 2em;
}

.icone-bt {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.buttonAddress {
  width: 248px;
  height: 90px;
  background: #ffffff;
  border: 1px solid #0f0f0f;
  box-sizing: border-box;
  border-radius: 5%;
  display: inline-block;
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
}
.select-title {
  width: 100%;
}

.imageUpload {
  width: 200px;
  height: 200px;
}

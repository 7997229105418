.popup_inner_url.align-middle {
  border-radius: 10% !important;
  height: 50%;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

@media only screen and (min-width: 600px) {
  .popup_inner_url {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
  }
}

@media only screen and (max-width: 600px) {
  .popup_inner_url {
    position: absolute;
    left: 3%;
    right: 3%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
  }
  .space-url {
  }
}

/* aligne vertical for bootstrap */
.align-middle {
  display: flex;
  align-items: center;
}
input#url {
  width: 90%;
}

.url-move {
  display: block;
  margin-bottom: 0.5rem;
}
@media (max-width: 550px) {
  input#url {
    width: 207px;
  }
}

@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

.fa-2x {
  font-size: 2em;
}

@media only screen and (max-width: 600px) {
  .phone-menu {
  }
  .selectpicker {
    background-color: rgb(52 58 64) !important;
    border: 0px;
    color: white;
  }
  .selectpicker > option {
    cursor: pointer;
  }
  .pl-5-model {
    padding-left: 0px !important;
  }
  .fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 27px !important;
    padding: 5px;
  }
}
.selectpicker-horizon {
  background-color: rgb(236 236 236) !important;
  border: 0px;
  color: black;
}
@media only screen and (min-width: 600px) {
  .phone-menu {
    display: none;
  }
  .selectpicker {
    background-color: rgb(33 33 33) !important;
    border: 0px;
    color: white;
  }
  .selectpicker > option {
    cursor: pointer;
  }
  .fa-2x {
    font-size: 2em;
  }
  .fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
  }
}

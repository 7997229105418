.btn-excel-add-card {
  width: 8%;
  height: 8%;
  cursor: pointer;
  background-color: silver;
  border-radius: 50%;
  padding: 1em;
  margin: 1em;
}

.btn-excel-add-card:hover {
  background-color: rgb(138, 137, 137);
}
@media only screen and (min-width: 600px) {
  .pad-menu-left {
    margin-left: 70px;
    margin-right: 10px;
    max-width: 100%;
    flex: 0 1 100%;
  }
}

@media only screen and (max-width: 600px) {
  .pad-menu-left {
    margin-left: 10px;
    margin-right: 10px;
    max-width: 100%;
    flex: 0 1 100%;
  }
}
/* For width 400px and larger: */
@media all and (min-width: 200px) and (max-width: 500px) {
  .btn-excel-add-card {
    width: 25%;
    height: 25%;
    cursor: pointer;
    background-color: silver;
    border-radius: 50%;
    padding: 1em;
    margin: 1em;
  }
}

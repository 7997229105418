.active {
  display: block;
}
.navbar-bg {
  background-color: rgb(236, 236, 236) !important;
}
.selectpicker-white {
  background-color: rgb(236, 236, 236) !important;
  border: 0px;
}

.textcolor {
  color: black !important;
  margin-left: 30px;
}
